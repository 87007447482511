function insertNumberCartItems(cartItems) {
    $(document).ajaxStop(function() {
        // var atLeastOneItem = cartItems >= 1;
        // if(atLeastOneItem){
        $('.maf-minicart-icon').each(function() {
            // console.log($(this));
            //$(this).addClass('show-icon'); //removing for launch, we will come back to it later
            $(this).find('.minicart-quantity')[0].innerHTML = cartItems;
        });
        // } else{
        // $('.maf-minicart-icon').each(function() {               
        //     //something else;
        //     $(this).find('.minicart-quantity')[0].innerHTML = cartItems;
        // });            
        // }
    });
}

function updateMiniCart(data) {
    var cartItems = data.cartItems.length;
    insertNumberCartItems(cartItems);
}

function callAjaxCartProductLine() {
    var url = "/on/demandware.store/Sites-medicalert-Site/en_US/Cart-CheckProductLineItem";
    $.ajax({
        url:           url,
        type:          'GET',
        async:         true,
        jsonpCallback: 'jsonCallback',
        contentType:   "application/json",
        dataType:      'jsonp',
        success:       function(data) {
            updateMiniCart(data);
        }
    });
}

callAjaxCartProductLine();
